import React from 'react';
import Contact from '../shared/Contact';
import Hero from '../shared/Hero';
import ButtonWp from '../shared/Buttonwp';

function Investments() {
  return (
    <div className='main-section investments' >
      <Hero image={'../images/banner-inversiones.jpeg'} title={''} />
      <section className='investments__description bg-white'>
        <div className='container'>
          <div className='row py-md-5 justify-content-center'>
            <div className='col-12 col-md-9 col-lg-8'>
              <p className='text text-segoe text-center'>
                Contamos con una amplia cartera de productos para invertir en el exterior, propiedades con costos accesibles que se adaptan a cada tipo de inversor.
              </p>
              <p className='text text-segoe text-center'>
                Lo importante es desarrollar una estrategia para obtener una adecuada rentabilidad consistente a mediano plazo. <br /> De eso se trata invertir.
              </p>
            </div>
            <div className='col-12 col-md-9 col-lg-8'>
              <p className='text text-segoe text-center'>
                <strong>En Brasil </strong>contamos con matrícula habilitante (CRECI 26375) y una amplia trayectoria en el mercado.
              </p>
              <p className='text text-segoe text-center'>
                También contamos con una cartera de desarrollos <strong>en Paraguay y Miami</strong>.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Contact />
      <ButtonWp />
    </div>
  );
}

export default Investments;
