import React from 'react';

function Agent ({name, position, image, phone, tel, mail, enrollment }) {
  return (
    <div
      className='agent-card'
      data-aos='zoom-in'
    >
      <div className='agent-card__profile mx-auto'>
        <img className='img-fluid' src={image} alt={name} />
      </div>
      <div className='agent-card__body text-center'>
        <h2 className='h2 name'>{name}</h2>
        {
          enrollment !== '' ? <div><p className='position mb-1'>{position}</p> <p className='position'>{enrollment}</p></div> : <p className='position'>{position}</p>
        }
        <ul>
          <li className='contact-info phone'>
            <a className='ft-text' href={`tel:${tel}`} target='_blank' rel='noopener noreferrer'>
              <img className='img-fluid' src='../images/phone-black.svg' alt='Phone'/>{phone}
            </a>
          </li>
          <li className='contact-info mail'>
            <a className='ft-text' href={`mailto:${mail}`} target='_blank' rel='noopener noreferrer'>
              <img className='img-fluid' src='../images/envelope-black.svg' alt='E-mail'/>{mail}
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Agent;