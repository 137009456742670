
import React from 'react';

function ButtonWp({ publicationTitle }) {
  const encodedTitle = encodeURIComponent(publicationTitle);
  const whatsappLink = `https://api.whatsapp.com/send?phone=5493624698256&text=Hola!%20vi%20su%20web%20y%20queria%20consultarles%20por%20${encodedTitle}`;

  return (
    <a
      href={whatsappLink}
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className='button-wp'>
        <img
          src='/images/whatsapp_contacto.svg'
          alt='Whatsapp'
        />
      </div>
    </a>
  );
}

export default ButtonWp;
