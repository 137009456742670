import React from 'react';  
import Contact from '../shared/Contact'; 
import Hero from '../shared/Hero';
import ButtonWp from '../shared/Buttonwp';

function Services() {
  return (
    <div className='main-section services'>
      <Hero image={'../images/hero-services.jpg'} title={''} />
      <section className='bg-white pt-5 pb-5'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-9 col-lg-8'>
              <p className='text text-segoe text-center'>
                Somos una empresa joven dedicada a satisfacer las necesidades de
                nuestros clientes, entendiendo sus necesidades y buscando las
                oportunidades del mercado inmobiliario. <br /> Nos enfocamos en
                el desarrollo de proyectos para familias y personas como vos,
                que buscan comodidad, ubicación, diseño y seguridad. Contamos
                con software inmobiliario para administración y CRM para
                comercializar, esto acompañado por un equipo de profesionales
                que nos capacitamos constantemente para innovar en el mercado y
                poder brindar un servicio óptimo.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-white services__type mb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h1 className='section-title'>Te podemos ayudar en:</h1>
            </div>
            <div
              className='col-12 col-md-4 col-lg-4 text-left'
              data-aos='zoom-in'
              data-aos-delay='0'
            >
              <div className='type-card admin d-flex justify-content-start align-items-center'>
                <div className='type-card__icon'>
                  <img className='img-fluid' src='../images/user-edit-active.svg' alt='Administración' />
                </div>
                <div className='type-card__title'>
                  <h2 className='h2'>Administración</h2>
                </div>
              </div>
              <div className='description'>
                <p className='text text-segoe'>
                  <strong>Administramos el alquiler de su propiedad</strong>
                </p>
                <p className='text text-segoe'>
                  Contamos con un software de gestión para realizar el servicio de cobranza del alquiler
                  de su propiedad, el control del pago de impuestos, tasas y servicios que corresponden
                  al inquilino y la administración de su propiedad. Revisamos su contrato de alquiler y lo
                  asesoramos en la selección del siguiente inquilino.
                </p>
              </div>
            </div>
            <div
              className='col-12 col-md-4 col-lg-4 text-left'
              data-aos='zoom-in'
              data-aos-delay='150'
            >
              <div className='type-card appraisals d-flex justify-content-start align-items-center' >
                <div className='type-card__icon'>
                  <img className='img-fluid' src='../images/coins-active.svg' alt='Tasaciones' />
                </div>
                <div className='type-card__title'>
                  <h2 className='h2'>Tasaciones</h2>
                </div>
              </div>
              <div className='description'>
                <p className='text text-segoe'>
                  <strong>Tasamos tu propiedad con gran precisión para que tu operación sea exitosa</strong>
                </p>
                <p className='text text-segoe'>
                  Esta valoración consiste en el equilibrio justo entre la oferta y la demanda, atendiendo
                  diferentes factores como las características edilicias, la superficie, la ubicación y el
                  entorno de la propiedad, para asegurar el éxito de una operación.
                </p>
                <p className='text text-segoe'>
                  Peralta Juarez le proporciona un servicio de Tasación idóneo, como primera referencia en la toma de decisiones en la compra-ventas inmobiliarias.
                </p>
              </div>
            </div>
            <div
              className='col-12 col-md-4 col-lg-4 text-left'
              data-aos='zoom-in'
              data-aos-delay='300'
            >
              <div className='type-card advice d-flex justify-content-start align-items-center'>
                <div className='type-card__icon'>
                  <img className='img-fluid' src='../images/file-invoice-active.svg' alt='Asesoramiento' />
                </div>
                <div className='type-card__title'>
                  <h2 className='h2'>Asesoramiento</h2>
                </div>
              </div>
              <div className='description'>
                <p className='text text-segoe'>
                  Nuestro equipo de profesionales realiza el asesoramiento y análisis de oportunidades en los procesos de inversión, tanto para inversores particulares, sociedades inmobiliarias y empresas.
                </p>
                <p className='text text-segoe'>
                  El asesoramiento consisten en realizar un estudio detallado cuyo contenido abarca todo lo necesario para la ejecución de un proceso de compra o venta de un inmueble o de un terreno:
                </p>
                <p className='text text-segoe'>
                  <li>Análisis y estudio del inmueble desde un punto de vista comercial y estimación del valor.</li>
                  <li>Auditoría sobre las instalaciones y aspectos urbanísticos y estructurales del inmueble.</li>
                  <li>Asesoramiento legal y financiero en la negociación y el cierre de la operación. </li>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Contact />
      <ButtonWp />
    </div>
  );
}

export default Services;
