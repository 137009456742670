import React from 'react';


function ButtonWp() {
  return (
    <a
      href='https://api.whatsapp.com/send?phone=5493624698256&app=facebook&entry_point=page_cta&fbclid=IwAR1mXfGnD38XhlBkCAZXwOnckGdIWNEB6fZv_ZCqteZWRxm5Na3AGHJUD08&text=Hola,%20estoy%20visitando%20la%20web%20y%20quiero%20saber%20informaci%C3%B3n%20sobre'
      target='_blank'
      rel='noopener noreferrer'
    >
      <div
        className='button-wp'
      >
        <img
          src='/images/whatsapp_contacto.svg'
          alt='Whatsapp'
        />
      </div>
    </a>
  )
}

export default ButtonWp;