import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Slider from '../shared/Carousel'
import Contact from '../shared/Contact'
import Seacher from '../shared/searcher'
// import PropertyList from '../shared/property-list'
import { API_KEY } from '../../env'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import SectionEmprendimientos from '../shared/SectionEmprendimientos'

import { setFilter } from '../../actions'
import ButtonWp from '../shared/Buttonwp'

const _fetch = async (setState) => {
  // curl 'http://www.tokkobroker.com/portals/simple_portal/api/v1/freeportals/?api_key=APIKEY&format=json&lang=es-AR&filter=updated&date_from=2015-12-31T00:00:00'
  const { data } = await axios.get(
    `https://tokkobroker.com/api/v1/property/search?lang=es_ar&format=json&order_by=random&order=DESC&data=%7B%22price_from%22%3A0%2C%22price_to%22%3A4500000%2C%22operation_types%22%3A%5B1%2C2%2C3%5D%2C%22property_types%22%3A%5B1%2C2%2C3%2C4%2C5%2C7%2C8%2C9%2C13%2C14%2C16%2C23%2C25%5D%2C+%22currency%22%3A%22USD%22%7D&key=${API_KEY}`
  )
  const { objects } = data

  setState(objects.splice(-4))
}

// const _slides = async (setState) => {
//   const { data } = await axios.get(
//     `https://peraltajuarez.com.ar/admin/api/v1/carousel-home`
//   )
//   setState(data)
// }

function FilterList({ filters }) {
  const dispatch = useDispatch()
  return filters.map((filter, i) => (
    <div key={filter.label} className='col-12 col-xs-12 col-sm-3 item'>
      <div
        className='welcome__card'
        style={{ backgroundImage: `url(${filter.src})` }}
        data-aos={i < 1 ? 'fade-right' : 'fade-up'}
      >
        <NavLink
          to={`/propiedades?operacion=${filter.value}`}
          onClick={() =>
            dispatch(setFilter({ id: filter.value, filter: 'operation_type' }))
          }
        >
          <div className='welcome__card-button'>
            <button className='btn text-uppercase'>{filter.cardTitle}</button>
          </div>
        </NavLink>
      </div>
    </div>
  ))
}

function Home() {

  const slides = [
    {
      id: 1,
      tipo_desktop: "imagen",
      tipo_mobile: "imagen",
      media_desktop: "images/slides/duplex-francia-web-banner-sin-logo.jpg",
      publicado: 1,
      created_at: "2020-08-26T23:34:45.000000Z",
      updated_at: "2021-07-28T14:47:20.000000Z"
    },
    {
      id: 2,
      tipo_desktop: "imagen",
      tipo_mobile: "imagen",
      media_desktop: "images/slides/edificio-troche-web-banner-sin-logo.jpg",
      publicado: 1,
      created_at: "2020-08-26T23:34:45.000000Z",
      updated_at: "2021-07-28T14:47:20.000000Z"
    },
    {
      id: 3,
      tipo_desktop: "imagen",
      tipo_mobile: "imagen",
      media_desktop: "images/slides/labrava-web-banner-sin-logo.jpg",
      publicado: 1,
      created_at: "2020-08-26T23:34:45.000000Z",
      updated_at: "2021-07-28T14:47:20.000000Z"
    },
    {
      id: 4,
      tipo_desktop: "imagen",
      tipo_mobile: "imagen",
      media_desktop: "images/slides/terraza-cortes-web-banner-sin-logo.jpg",
      publicado: 1,
      created_at: "2020-08-26T23:34:45.000000Z",
      updated_at: "2021-07-28T14:47:20.000000Z"
    }
  ]

  const [properties, setProperties] = useState([])
  // const [slides, setSlides] = useState([])
  const { filters } = useSelector((state) => ({
    filters: state.filters.operation_types,
  }))

  useEffect(() => {
    _fetch(setProperties)
    // _slides(setSlides)
  }, [])

  return (
    <div className='main-section home'>
      <Slider slides={slides} home={true}/>
      <section className='welcome'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <h1 className='section-title'>¡Bienvenido!</h1>
              <h2 className='section-subtitle'>¿Qué buscas hoy?</h2>
            </div>
            <Seacher list={false} showDesktop={false} />
          </div>
          <div className='test'>
            <div className='row'>
              {filters.length >= 1 ? (
                <FilterList filters={filters.slice(1, 3)} />
              ) : (
                ''
              )}

              <div className='col-12 col-xs-12 col-sm-3 item'>
                <div
                  className='welcome__card'
                  style={{ backgroundImage: `url(../images/card-vender.png)` }}
                  data-aos='fade-up'
                >
                  <NavLink to={'/vender'}>
                    <div className='welcome__card-button'>
                      <button className='btn'>VENDER</button>
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className='col-12 col-xs-12 col-sm-3 item'>
                <div
                  className='welcome__card'
                  style={{
                    backgroundImage: `url(../images/card-inversiones.png)`,
                  }}
                  data-aos='fade-left'
                >
                  <NavLink to={'/inversiones-en-el-exterior'}>
                    <div className='welcome__card-button'>
                      <button className='btn'>
                        INVERSIONES EN EL EXTERIOR
                      </button>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='featured'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='section-title text-center'>Nuestros emprendimientos</h1>
            </div>
          </div>
          <SectionEmprendimientos />
        </div>
      </section>
      <Contact />
      <ButtonWp />
    </div>
  )
}

export default Home
