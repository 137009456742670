import React from 'react';  
import Contact from '../shared/Contact'
import SectionEmprendimientos from '../shared/SectionEmprendimientos'
import Hero from '../shared/Hero'
import ButtonWp from '../shared/Buttonwp'

function Emprendimientos() {
  return (
    <div className='main-section services'>
      <Hero image={'../images/hero-about-us.jpg'} title={''}/>
      {/* <section className='bg-white pt-5 pb-5'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-9 col-lg-8'>
              <p className='text text-segoe text-center'>
                Somos una empresa joven dedicada a satisfacer las necesidades de
                nuestros clientes, entendiendo sus necesidades y buscando las
                oportunidades del mercado inmobiliario. <br /> Nos enfocamos en
                el desarrollo de proyectos para familias y personas como vos,
                que buscan comodidad, ubicación, diseño y seguridad. Contamos
                con software inmobiliario para administración y CRM para
                comercializar, esto acompañado por un equipo de profesionales
                que nos capacitamos constantemente para innovar en el mercado y
                poder brindar un servicio óptimo.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <section className='bg-white services__type mb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='section-title mb-0'>Nuestros</h1>
              <h1 className='section-title'><strong>Emprendimientos</strong></h1>
            </div>
          </div>
          <SectionEmprendimientos />
        </div>
      </section>
      <Contact />
      <ButtonWp />
    </div>
  );
}

export default Emprendimientos;
