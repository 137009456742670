import { API_KEY } from '../env'

const initialState = {
  properties: [],
  error: null,
  loading: true,
  metaInfo: {},
  url: `https://www.tokkobroker.com/api/v1/property/?format=json&key=${API_KEY}&lang=es_ar&limit=12&order_by=-deleted_at`,
  urlSearch: `https://tokkobroker.com/api/v1/property/search?key=${API_KEY}&lang=es_ar&format=json&limit=12&order_by=-deleted_at`,
  nextUrl: null,
  filters: {},
  property_type: null,
  operation_type: null,
  property_code: null
}

const properties = (state = initialState, action) => {
  const { data, type } = action

  switch (type) {
    case 'SET_PROPERTIES':
      return { ...state, properties: data }

    case 'ADD_MORE_PROPERTIES':
      return { ...state, properties: [...state.properties, ...data] }

    case 'SET_META_INFO':
      return { ...state, metaInfo: data, nextUrl: data.next }

    case 'TOGGLE_LOADING':
      return { ...state, loading: data }

    case 'SET_FILTER':
      return {
        ...state,
        filters: { ...state.filters, [data.filter]: data.id },
        [data.filter]: data.id,
      }

    case 'SET_FILTER_MIN_PRICE':
      // console.log('min', typeof data)
      return {
        ...state,
        filters: {
          ...state.filters,
          price_min: data,
        },
      }
    case 'SET_FILTER_MAX_PRICE':
      // console.log('max', typeof data)
      return {
        ...state,
        filters: {
          ...state.filters,
          price_max: data,
        },
      }
      case 'SET_PROPERTY_CODE' :
        // console.log('inputs should disable now', data)
        return {
          ...state,
          property_code: data
        }

    default:
      return state
  }
}

export default properties
