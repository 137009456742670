import React from 'react';  
import Hero from '../shared/Hero';
import Form from '../shared/Form';
import Offfices from '../shared/Offices';
import ButtonWp from '../shared/Buttonwp';

function ContactPage() {
  return (
    <div className='main-section contact-page' >
      <Hero image={'../images/hero-contact.jpg'} title={'Contacto'} />
      <section className='contact-page__description bg-white pb-5'>
        <div className='container'>
          <div className='row'>
            <Form sectionClass={''}/>
            <Offfices />
          </div>
        </div>
      </section>
      <ButtonWp />
    </div>
  );
}

export default ContactPage;
