import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import VideoPlayer from './VideoPlayer';

const Slider = ({ slides, home }) => {
  if (home) {
    return (
      <Carousel
        showArrows={true}
        showIndicators={false}
        showThumbs={false}
        emulateTouch={true}
        showStatus={false}
        autoPlay={true} // Agregar autoPlay para que funcione automáticamente
        interval={7000} // Opcional: ajustar el intervalo de tiempo entre las diapositivas
        infiniteLoop={true} // Agregar infiniteLoop para que el carrousel sea un bucle


      >
        {slides &&
          slides.map((slide) => (
            <div key={slide.id}>
              <div className='slide-mobile'>
                {slide.tipo_mobile === 'imagen' ? (
                  <div className='slide-container'>
                    <img
                      id='carousel-img'
                      src={slide.media_desktop}
                      alt={slide.titulo}
                    />
                    <div className='slide-content'>
                    <img
                      className='logo-emprendimientos'
                      src={slide.logo}
                      alt={slide.titulo}
                      />
                      {/* <h1>{slide.titulo}</h1> */}
                      <p>{slide.descripcion}</p>
                      {/* <button>{slide.boton}</button> */}
                    </div>
                  </div>
                ) : (
                  <VideoPlayer
                    tipo={slide.tipo_mobile}
                    src={slide.media_mobile}
                    height="100%"
                  />
                )}
              </div>
              <div className='slide-desktop'>
                {slide.tipo_desktop === 'imagen' ? (
                  <div className='slide-container'>
                    <img
                      src={slide.media_desktop}
                      alt={slide.titulo}
                    />
                    <div className='slide-content'>
                      <img
                      className='logo-emprendimientos'
                      src={slide.logo}
                      alt={slide.titulo}
                      />
                      {/* <h1>{slide.titulo}</h1> */}
                      <p>{slide.descripcion}</p>
                      {/* <button>{slide.boton}</button> */}
                    </div>
                  </div>
                ) : (
                  <VideoPlayer
                    tipo={slide.tipo_desktop}
                    src={slide.media_desktop}
                  />
                )}
              </div>
            </div>
          ))}
      </Carousel>
    );
  }

  return (
    <Carousel
      showArrows={true}
      showIndicators={false}
      showThumbs={false}
      emulateTouch={true}
      showStatus={false}
      autoPlay={true} // Agregar autoPlay para que funcione automáticamente
      interval={7000} // Opcional: ajusta el intervalo de tiempo entre las diapositivas
      infiniteLoop={true} // Agregar infiniteLoop para que el carrousel sea un bucle


    >
      {slides &&
        slides.map((slide) => (
          <div key={slide.original}>
            <img
              src={slide.original}
              alt='slider 1'
              style={{
                objectFit: 'contain',
                objectPosition: 'center',
                backgroundColor: '#000000'
              }}
            />
          </div>
        ))}
    </Carousel>
  );
};

const slides = [
  {
    id: 1,
    logo: "images/logo-duplexFrancia.svg",
    titulo: "Duplex Francia",
    descripcion: "Unidades próximas a entregar, diseñadas con un enfoque único en funcionalidad y estilo.",
    boton: "",
    media_desktop: "images/slides/duplex-francia-banner-actualizado-mayo.jpeg",
    media_mobile: "images/slides/duplex-francia-banner-actualizado-mayo-mobile.jpg",
    tipo_mobile: "imagen",
    tipo_desktop: "imagen"
  },
  {
    id: 2,
    logo: "images/logo-edificioTroche.svg",
    titulo: "Edificio Troche",
    descripcion: "¿Pensando en invertir? Edificio TROCHE, te ofrece un beneficio exclusivo. Departamentos a estrenar con renta en dólares",
    boton: "",
    media_desktop: "images/slides/edificio-troche-web-banner-sin-logo.jpg",
    media_mobile: "ruta-de-la-imagen-mobile-2.jpg",
    tipo_mobile: "imagen",
    tipo_desktop: "imagen"
  },
  {
    id: 3,
    logo: "images/logo-laBrava.svg",
    titulo: "La Brava Barrio & Chacras Golf",
    descripcion: "Descubrí tu lugar ideal en armonía con la naturaleza. Lotes en Venta, financiados hasta 120 Cuotas.",
    boton: "",
    media_desktop: "images/slides/labrava-web-banner-sin-logo.jpg",
    media_mobile: "ruta-de-la-imagen-mobile-2.jpg",
    tipo_mobile: "imagen",
    tipo_desktop: "imagen"
  },
  {
    id: 4,
    logo: "images/logo-terrazaCortes.svg",
    titulo: "Terraza Cortes",
    descripcion: "Edificio en construcción ubicado en Av. Avalos 245, una de las principales avenidas de Resistencia. Destacamos su excelente ubicación.",
    boton: "",
    media_desktop: "images/slides/terraza-cortes-banner-actualizado-mayo.jpeg",
    media_mobile: "images/slides/terraza-cortes-banner-actualizado-mayo-mobile.jpeg",
    tipo_mobile: "imagen",
    tipo_desktop: "imagen"
  },
  // ... más objetos slide
];

export default () => <Slider slides={slides} home={true} />;




