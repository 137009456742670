import React from "react";
import ReactPlayer from "react-player";

const APP_ENV = "admin";
const BASE_PATH = APP_ENV === "local" ? "http://localhost:8000" : "https://peraltajuarez.com.ar/admin/public/videos";

const VideoPlayer = ({ tipo, src, height = "100%" }) => {
    // console.info("tipo: ", tipo);
    if (tipo === "youtube") {
        return (
            <ReactPlayer
                url={src}
                width="100%"
                height={height}
                controls
                volume={3}
                muted={false}
            />
        );
    } else {
        return (
            <ReactPlayer
                url={`${BASE_PATH}/${src}`}
                width="100%"
                height={height}
                controls
                volume={1}
                muted={false}
            />
        );
    }
};

export default VideoPlayer;
