import React, { useState, useEffect } from 'react';
import Agent from '../shared/Agent';
import Contact from '../shared/Contact';
import Hero from '../shared/Hero';
import ButtonWp from '../shared/Buttonwp';
import axios from 'axios';

function AboutUs() {
  const [agents, setAgents] = useState([]);
  useEffect(() => {
    axios
      .get('/agents.json')
      .then((res) => {
        // console.log(res);
        setAgents(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className='main-section about-us'>
      <Hero image={'../images/hero-nosotros-3.jpg'} title={''} />
      <section className='bg-white pt-5 pb-5'>
        <div className='container'>
          <div className='row justify-content-center'> 
            <div className='col-12 col-md-9 col-lg-8'>
              <p className='text text-segoe text-center'>
                Somos una empresa joven dedicada a satisfacer las necesidades de
                nuestros clientes, entendiendo sus necesidades y buscando las
                oportunidades del mercado inmobiliario. <br /> Nos enfocamos en
                el desarrollo de proyectos para familias y personas como vos,
                que buscan comodidad, ubicación, diseño y seguridad. Contamos
                con software inmobiliario para administración y CRM para
                comercializar, esto acompañado por un equipo de profesionales
                que nos capacitamos constantemente para innovar en el mercado y
                poder brindar un servicio óptimo.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-white about-us__agents'>
        <div className='container'>
          <div className='row'>
            {agents.map(({ id, name, position, phone, tel, mail, image, enrollment }) => (
              <div key={id} className='col-12 col-md-6 col-lg-4'>
                <Agent
                  name={name}
                  position={position}
                  phone={phone}
                  tel={tel}
                  mail={mail}
                  image={image}
                  enrollment={enrollment}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <Contact />
      <ButtonWp />
    </div>
  );
}

export default AboutUs;
