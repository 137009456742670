import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'

function Suscribite () {
  const [sent, setSent] = useState(false)
  const [notSent, setNotSent] = useState(false)
  const [sending, setSending] = useState(false)
  const { handleSubmit, register, errors } = useForm()

  const onSubmit = (values) => {
    setSending(true)
    const form = new FormData()
    form.append('email', values.email)

    axios
      .post('https://peraltajuarez.com.ar/admin/api/v1/suscripcion-newsletter', form)
      .then((res) => {
        const message = res.data
        setSending(false)
        if (message === 'Gracias por suscribirte.') {
          setSent(true)
          // document.querySelector('#form-suscribite').reset()
        } else if (message === 'Lo sentimos. No pudo ser enviada, aunque es posible que el administrador lea su mensaje'){
          setSent(true)
        } else {
          setNotSent(true)
        }
      })
      .catch((error) => {
        // console.log('catch ', error)
        setNotSent(true)
      })
  }


  return(
    <>
      {sent && (
        <div className='alert alert-success' role='alert'>
          <strong>¡Gracias!</strong>{' '}
        </div>
      )}
      {notSent && (
        <div className='alert alert-danger' role='alert'>
          <strong>¡Ups!</strong> Algo salió mal, intenta de nuevo.{' '}
        </div>
      )}
      <form
          onSubmit={handleSubmit(onSubmit)}
          id='form-suscribite'
        >
        <div className='input-group'>
          <input
            type='email'
            className={
              errors.email ? 'form-control is-invalid' : 'form-control'
            }
            placeholder='Correo electrónico'
            name='email'
            ref={register({
              required: 'Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              },
            })}
          />
          {errors.email && (
            <div className='invalid-feedback'>
              Debes ingresar un emial válido.
            </div>
          )}
          <div className='input-group-prepend'>
            <button
              type='submit'
              className='button'
              disabled={sending}
            >
              <span className='input-group-text'>OK</span>
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default Suscribite;