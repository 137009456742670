import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setFilter } from '../../actions'

const FunctionLinks = () => {
  const { filters } = useSelector((state) => ({
    filters: state.filters.operation_types,
  }))
  const dispatch = useDispatch()

  return filters.slice(1, 3).map((filter) => (
    <li className='item sub-item' key={filter.label}>
      <a
        className='item-link text-uppercase'
        href={`/propiedades?operacion=${filter.value}`}
      >
        {filter.cardTitle}
      </a>
    </li>
  ))
}
class Navbar extends Component {
  constructor(props) {
    super(props)
    this.openMenu = this.openMenu.bind(this)

    this.state = {
      active: false,
    }

    window.onscroll = () => {
      const header = document.querySelector('.header')
      if (document.documentElement.scrollTop > 0) {
        header.classList.add('active')
        this.setState({ active: true })
      } else {
        if (header.classList.contains('active')) {
          header.classList.remove('active')
          this.setState({ active: false })
        }
      }
    }
  }

  openMenu = () => {
    document.querySelector('.navbar').classList.toggle('nav-open')
    document.querySelector('.header-item-group').classList.toggle('active')
  }

  render() {
    return (
      <>
        <div className='top-menu d-flex align-items-center'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 d-flex justify-content-end align-items-center'>
                <ul className='phone-number'>
                  <li>
                    <a
                      href='https://api.whatsapp.com/send?phone=+5493624698256'
                      target='_blank'
                      rel='noopener noreferrer'>
                      +54 9 362 469 8256
                    </a>
                  </li>
                </ul>
                <ul className='redes-sociales'>
                  <li className='header-item-group__item rrss fb'>
                    <a
                      href='https://www.facebook.com/peraltajuareznegociosinmobiliarios'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <span>
                        <img
                          src='/images/facebook-square-brands.svg'
                          alt=''
                          className='img-fluid'
                        />
                      </span>
                    </a>
                  </li>
                  <li className='header-item-group__item rrss ig'>
                    <a
                      href='https://www.instagram.com/peraltajuarez_/'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <span>
                        <img
                          src='/images/instagram-brands.svg'
                          alt=''
                          className='img-fluid'
                        />
                      </span>
                    </a>
                  </li>
                  <li className='header-item-group__item rrss yt'>
                    <a
                      href='https://www.youtube.com/channel/UC4Fhoy5WhUYuhnuk8GdLFhg/'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <span>
                        <img
                          src='/images/youtube-brands.svg'
                          alt=''
                          className='img-fluid'
                        />
                      </span>
                    </a>
                  </li>
                  <li className='header-item-group__item rrss yt'>
                    <a
                      href='https://www.tiktok.com/@peralta.juarez.in?_t=8eMnoywllq9&_r=1'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <span>
                        <img
                          src='/images/tiktok-brands.svg'
                          alt=''
                          className='img-fluid'
                        />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <nav className='header'>
          <div className='container'>
            <div className='row'>
              <div className='col-8 col-lg-3 my-auto'>
                <NavLink exact to={`/`}>
                  <img
                    src={
                      !this.state.active
                        ? '/images/logo-peralta-juarez.svg'
                        : '/images/logo-horizontal.svg'
                    }
                    alt='Peralta Juarez logo'
                    className='img-fluid header__logo ml-auto ml-lg-0'
                  />
                </NavLink>
              </div>
              <div className='col-4 col-lg-9 my-auto'>
                <div
                  className='botton-menu align-self-center'
                  onClick={this.openMenu}
                >
                  <div className='navbar navbar box-shadow'>
                    <div className='nav-button'>
                      <span id='nav-icon3'>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </span>
                    </div>
                  </div>
                </div>
                <ul className='header-item-group d-lg-flex justify-lg-content-between'>
                  <li className='header-item-group__item'>
                    <NavLink
                      exact
                      to={`/`}
                      className='item-link'
                      activeclassname='active'
                    >
                      Inicio
                    </NavLink>
                  </li>
                  <li className='header-item-group__item'>
                    <NavLink
                      exact
                      to={`/propiedades`}
                      className='item-link'
                      activeclassname='active'
                    >
                      Propiedades
                    </NavLink>
                    <ul className='item-group sub-group'>
                      <FunctionLinks />
                      <li className='item sub-item'>
                        <NavLink to={`/vender`} className='item-link'>
                          VENDER
                        </NavLink>
                      </li>
                      <li className='item sub-item'>
                        <NavLink
                          to={`/inversiones-en-el-exterior`}
                          className='item-link'
                        >
                          INVERSIONES EN EL EXTERIOR
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li className='header-item-group__item emprendimiento'>
                    <NavLink
                      to={`/emprendimientos`}
                      className='item-link'
                      activeclassname='active'
                    >
                      Emprendimientos
                    </NavLink>
                  </li> */}
                  <li className='header-item-group__item'>
                    <NavLink
                      to={`/servicios`}
                      className='item-link'
                      activeclassname='active'
                    >
                      Servicios
                    </NavLink>
                    <ul className='item-group sub-group'>
                      <li className='item sub-item'>
                        <NavLink to={`/servicios`} className='item-link'>
                          ADMNISTRACIÓN
                        </NavLink>
                      </li>
                      <li className='item sub-item'>
                        <NavLink to={`/servicios`} className='item-link'>
                          TASACIONES
                        </NavLink>
                      </li>
                      <li className='item sub-item'>
                        <NavLink to={`/servicios`} className='item-link'>
                          ASESORAMIENTO
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className='header-item-group__item'>
                    <NavLink
                      to={`/nosotros`}
                      className='item-link'
                      activeclassname='active'
                    >
                      Nosotros
                    </NavLink>
                  </li>
                  <li className='header-item-group__item'>
                    <NavLink
                      to={`/emprendimientos`}
                      className='item-link'
                      activeclassname='active'
                    >
                      Emprendimientos
                    </NavLink>
                  </li>
                  <li className='header-item-group__item'>
                    <NavLink
                      to={`/contacto`}
                      className='item-link'
                      activeclassname='active'
                    >
                      Contacto
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </>
    )
  }
}

export default withRouter(Navbar)
