import { useDispatch, useSelector } from 'react-redux';
import {
	enableLoading,
	disableLoading,
	setMetaInfo,
	setProperties
} from '../actions';

import axios from 'axios';

const useFilterDirectProperties = () => {
  

  
	const dispatch = useDispatch();
	const { filters, url } = useSelector((state) => ({
    filters: state.properties.filters,
		url: state.properties.urlSearch,
	}));

	const filterData = async (type, value) => {
		try {
			dispatch(enableLoading());

			const params = {
				price_from: filters.price_min ? filters.price_min : 0,
				price_to: filters.price_max ? filters.price_max : 99999999,
				operation_types: type === 'operation_type'
					? [value]
					: filters.operation_type 
          ? filters.operation_type 
          : [1, 2, 3],
				property_types: type === 'property_type'
					? [value]
					: filters.property_type
          ? filters.property_type 
          : [1, 2, 3, 4, 5, 6, 7, 8]
			};

			localStorage.setItem("fetchUrl", `${url}&data=${JSON.stringify(params)}`)
			// console.log(params)

      const { data } = await axios.get(
        `${url}&data=${JSON.stringify(params)}`
      );

      dispatch(setProperties(data.objects));
      dispatch(setMetaInfo(data.meta));

			dispatch(disableLoading());
		} catch (error) {
			dispatch(disableLoading());
			console.error('ERROR WHILE FILTERING PROPS: ', error);
      alert('No se encontraron Resultados')
		}
	};

	return { filterData };
};

export default useFilterDirectProperties;
