import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      // te ensucio el código por acá jajaja
      if(document.querySelector('.header-item-group').classList.contains('active')) {
        document.querySelector('.navbar').classList.toggle('nav-open');
        document.querySelector('.header-item-group').classList.toggle('active');
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};

export default withRouter(ScrollToTop);
