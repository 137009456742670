import React, { useState } from 'react'

function Offices() {

  const [ office, setOffice ] = useState('resistencia')

  return(
    <div className='col-sm-12 col-lg-6 contact__map'>
      <div className='contact__map--tabs'>
        <ul className="tabs">
          <li className={'tabs__tab ' + (office === 'resistencia' ? 'active' : '')} onClick={() => setOffice('resistencia')}>
            <div className="tabs__tab--icon">
              <img className='img-fluid' src='../images/map-marker-alt-solid.svg' alt='Google maps' />
            </div>
            <div className="tabs__tab--title">
              Resistencia
            </div>
          </li>
          <li className={'tabs__tab ' + (office === 'villa-angela' ? 'active' : '')} onClick={() => setOffice('villa-angela')}>
            <div className="tabs__tab--icon">
              <img className='img-fluid' src='../images/map-marker-alt-solid.svg' alt='Google maps' />
            </div>
            <div className="tabs__tab--title">
              Villa Ángela
            </div>
          </li>
        </ul>
      </div>
      <div className='contact__map--info'>
        {
          office === 'resistencia' &&
          <a href='https://goo.gl/maps/8wHnDJHJEjt6CgNE8' target='_blank' rel='noopener noreferrer'>
            <img className='img-fluid' src='../images/google-maps-resistencia.png' alt='Sucursal Resistencia' data-aos='fade-left' />
          </a>
        }
        {
          office === 'villa-angela' &&
          <a href='https://goo.gl/maps/4Mgry25MD7GzYWyV9' target='_blank' rel='noopener noreferrer'>
            <img className='img-fluid' src='../images/google-maps-villa-angela.png' alt='Sucursal Villa Ángela' data-aos='fade-left' />
          </a>
        }
      </div>
    </div>
  )
}

export default Offices