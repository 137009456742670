const initialState = {
  property_types: [
    {
      label: 'TODOS',
      value: '',
    },
    {
      label: 'Terreno',
      value: 1,
    },
    {
      label: 'Departamento',
      value: 2,
    },
    {
      label: 'Casa',
      value: 3,
    },
    {
      label: 'Casa de fin de semana',
      value: 4,
    },
    {
      label: 'Oficina',
      value: 5,
    },
    {
      label: 'Local comercial',
      value: 7,
    },
    {
      label: 'Edificio comercial',
      value: 8,
    },
    {
      label: 'Galpón',
      value: 24,
    },

  ],
  operation_types: [
    {
      label: 'TODAS',
      value: '',
    },
    {
      label: 'Venta',
      value: 1,
      src: '../images/card-comprar.png',
      cardTitle: 'Comprar',
    },
    {
      label: 'Alquiler',
      value: 2,
      src: '../images/card-alquilar.png',
      cardTitle: 'Alquilar',
    },
    {
      label: 'Alquiler temporal',
      value: 3,
    },
  ],
}

const filters = (state = initialState, action) => {
  switch (action) {
    default:
      return state
  }
}

export default filters
