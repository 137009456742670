import React, { Component } from 'react';

class Error extends Component{
  render() {
    return (
      <div>Error</div>
    )
  }
}

export default Error;