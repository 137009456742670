import React from 'react';
import Form from '../shared/Form';
import Offices from './Offices';

function Contact() {
  return (
    <section className="contact">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="section-title">Contactate con nosotros</h1>
          </div>
        </div>
        <div className="row">
          <Form />
          <Offices />
        </div>
      </div>
    </section>
  )
}

export default Contact;