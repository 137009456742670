import React from 'react';

function Hero ({ image, title, subtitle }) {
  return (
    <section className="hero">
      <div
        className='hero--image'
      >
        <img
          src={image}
          alt={title}
        />
      </div>
      <div className='container'>
        <div className='row hero--title'>
          <div
            className='col-12 mb-3'
            data-aos='fade-right'
          >
            <h1 className='section-title' >
              {title}
            </h1>
            {
              subtitle && <h2 className='section-subtitle'>{subtitle}</h2>
            }
          </div>
        </div>
      </div>
    </section>
  )
}
export default Hero;