import { useDispatch, useSelector } from 'react-redux';
import {
	enableLoading,
	disableLoading,
	setMetaInfo,
	setProperties
} from '../actions';

import axios from 'axios';

const useFilterProperties = () => {
	const dispatch = useDispatch();
	const { filters, url, property_code } = useSelector((state) => ({
		filters: state.properties.filters,
		url: state.properties.urlSearch,
    property_code: state.properties.property_code
	}));

	const fetchData = async (e) => {
		// const popup = document.querySelector('#popup')
		// if (popup.classList.contains('popup')) {
		//   popup.classList.remove('popup')
		// }
		if (e) {
			e.preventDefault();
		}
		try {
			dispatch(enableLoading());

			const params = {
				price_from: filters.price_min ? filters.price_min : 0,
				price_to: filters.price_max ? filters.price_max : 99999999,
				operation_types: filters.operation_type
					? [filters.operation_type]
					: [1, 2, 3],
				property_types: filters.property_type
					? [filters.property_type]
					: [1, 2, 3, 4, 5, 6, 7, 8]
			};

			if (property_code) {
				const key = '8294000bd1b593b90e40496df1fee0d8aca98af6';
				const codeUrl = `https://tokkobroker.com/api/v1/property/${property_code}/?key=${key}&format=json&lang=es-AR&limit=12`;
				localStorage.setItem("fetchUrl", `${codeUrl}`)
				const { data } = await axios.get(codeUrl);

        window.location.replace(`/propiedades/${property_code}`)
			} else {
				localStorage.setItem("fetchUrl", `${url}&data=${JSON.stringify(params)}`)
				const { data } = await axios.get(
					`${url}&data=${JSON.stringify(params)}`
				);

				dispatch(setProperties(data.objects));
				dispatch(setMetaInfo(data.meta));
			}

			dispatch(disableLoading());
		} catch (error) {
			dispatch(disableLoading());
			console.error('ERROR WHILE FILTERING PROPS: ', error);
      alert('No se encontraron Resultados')
		}
	};

	return { fetchData };
};

export default useFilterProperties;
