import React, { Component } from 'react';

class Loader extends Component{

  constructor(props){
    super(props);

    window.onload = function() {
      placeholder();
      // var button = document.querySelector('.botton-menu');
      // button.addEventListener('click', function(){
      //   document.querySelector('.navbar').classList.toggle('nav-open');
      //   document.querySelector('.header-item-group').classList.toggle('active');
      // });
    };
    
    var placeholder = function(){
      setTimeout(function(){
        setTimeout(function(){
          var spinner = document.querySelector('#placeholder');
          spinner.style.display = 'none';
        }, 1000); 
        var fadeTarget = document.getElementById('placeholder');
        var fadeEffect = setInterval(function () {
            if (!fadeTarget.style.opacity) {
                fadeTarget.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
                fadeTarget.style.opacity -= 0.1;
            } else {
                clearInterval(fadeEffect);
            }
        }, 50);
        // $('.main-banner__content').addClass('bounceInLeft animated');
        // $('.main-banner__background').addClass('fadeIn animated');
      }, 1000);
    };
  }

  render(){
    return(
      <div id='placeholder' className='position-fixed'>
        <div className='d-flex justify-content-center align-items-center'>
          <div className='spinner-border text-normal' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    )
  }
}

export default Loader;