import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'

function Form({ title, property }) {
  const [sent, setSent] = useState(false)
  const [notSent, setNotSent] = useState(false)
  const [sending, setSending] = useState(false)
  const { handleSubmit, register, errors } = useForm()
  const onSubmit = (values) => {
    setSending(true)
    const form = new FormData()
    form.append('name', values.name)
    form.append('email', values.email)
    form.append('phone', values.phone)
    form.append('message', values.message)
    form.append('verif_peraltajuarez', '@soywaltergonzalez.peralta.Juarez/2023')

    axios
      .post('https://peraltajuarez.com.ar/services.php', form)
      .then((res) => {
        const message = res.data
        setSending(false)
        if (message === '¡Mensaje enviado con éxito!') {
          setSent(true)
          document.querySelector('#form-contact').reset()
        } else {
          setNotSent(true)
        }
      })
      .catch((error) => {
        // console.log('catch ', error)
        setNotSent(true)
      })
  }

  return (
    <div className='col-sm-12 col-lg-6 contact__form'>
      {title && <h4 className='mb-3 h4 text-center'> {title} </h4>}
      <form
        onSubmit={handleSubmit(onSubmit)}
        id='form-contact'
        data-aos='fade-right'
      >
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text' id='name'>
              <img className='' src='/images/name.svg' alt='icono' />
            </span>
          </div>
          <input
            type='text'
            className={errors.name ? 'form-control is-invalid' : 'form-control'}
            placeholder='Nombre'
            name='name'
            ref={register({
              required: 'Required',
            })}
          />
          {errors.name && (
            <div className='invalid-feedback'>Debes ingresar un Nombre.</div>
          )}
        </div>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text' id='email'>
              <img className='' src='/images/mail.svg' alt='icono' />
            </span>
          </div>
          <input
            type='email'
            className={
              errors.email ? 'form-control is-invalid' : 'form-control'
            }
            placeholder='Correo electrónico'
            name='email'
            ref={register({
              required: 'Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              },
            })}
          />
          {errors.email && (
            <div className='invalid-feedback'>
              Debes ingresar un emial válido.
            </div>
          )}
        </div>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text' id='phone'>
              <img className='' src='/images/phone.svg' alt='icono' />
            </span>
          </div>
          <input
            type='text'
            className={
              errors.phone ? 'form-control is-invalid' : 'form-control'
            }
            name='phone'
            placeholder='Teléfono'
            ref={register({
              required: 'Required',
            })}
          />
          {errors.phone && (
            <div className='invalid-feedback'>
              Debes ingresar un número de teléfono.
            </div>
          )}
        </div>
        <div className='input-group'>
          <textarea
            className='form-control'
            name='message'
            id='message'
            placeholder='Mensaje'
            ref={register}
            defaultValue={
              property &&
              `Hola, quiero recibir más información acerca de esta propiedad. Código de propiedad: ${property}`
            }
          ></textarea>
        </div>
        {sent && (
          <div className='alert alert-success' role='alert'>
            <strong>¡Gracias!</strong> Tu mensaje se envío correctamente{' '}
          </div>
        )}
        {notSent && (
          <div className='alert alert-danger' role='alert'>
            <strong>¡Ups!</strong> Algo salió mal, intenta de nuevo.{' '}
          </div>
        )}
        <div className='text-center mt-3'>
          <button
            type='submit'
            className='button btn--read-more hvr-shutter-out-horizontal'
            disabled={sending}
          >
            {sending && (
              <div className='spinner-border text-light' role='status'>
                {' '}
                <span className='sr-only'>Loading...</span>{' '}
              </div>
            )}
            ENVIAR
          </button>
        </div>
      </form>
    </div>
  )
}

export default Form
