import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter, setFilterMinPrice, setFilterMaxPrice } from '../../actions'

import useFilterProperties from '../../hooks/useFilterProperties'
import useFilterDirectProperties from '../../hooks/useFilterDirectProperties'

const optionList = ({ items }) => {
  return items.map((item) => (
    <option key={item.label} value={item.value}>
      {item.label}
    </option>
  ))
}

function Seacher({ list, showDesktop }) {
  const [popup, setPopup] = useState('')
  const buttonRef = useRef(null)

  const classAdvanced = showDesktop
    ? 'advanced text-center'
    : 'advanced text-center hide'

  const {
    operation_types,
    property_types,
    operation_type,
    property_type,
    property_code,
  } = useSelector((state) => ({
    operation_types: state.filters.operation_types,
    property_types: state.filters.property_types,
    property_type: state.properties.property_type,
    operation_type: state.properties.operation_type,
    property_code: state.properties.property_code,
  }))
  const dispatch = useDispatch()

  const { fetchData } = useFilterProperties()
  const { filterData } = useFilterDirectProperties()

  const filterAndClose = (e) => {
    fetchData(e)
    setPopup('')
  }

  const filter = (e, type) => {
    dispatch(
      setFilter({ id: e.target.value, filter: type })
    )
    filterData(type, e.target.value)
    setPopup('')
  } 

  return (
    <div className='col-12 col-xl-3 searcher'>
      <ul>
        <li
          className='searcher__input d-flex justify-content-between align-items-center'
          onClick={() => setPopup('popup')}
        >
          <p className='input'>
            COMPRAR
            <span>
              <img src='../images/search-solid.svg' alt='¿Qué buscas hoy?' />
            </span>
          </p>
          {list && (
            <p className='list'>
              <img
                className='img-fluid'
                src='../images/list-ul.svg'
                alt='Mostrar en lista'
              />
            </p>
          )}
        </li>
      </ul>

      <div className={popup} id='popup'>
        <div className={classAdvanced}>
          <div className='close' onClick={() => setPopup('')}>
            x
          </div>
          <h4 className='h4 mb-4'>Búsqueda avanzada</h4>
          <form onSubmit={(e) => filterAndClose(e)}>
            <div className='form-group'>
              <label>Tipo de Propiedad</label>
              <select
                className='form-control'
                disabled={!!property_code}
                style={{ backgroundImage: `url(../images/arrow-select.svg)` }}
                onChange={(e) => filter(e, 'property_type')}
                // onChange={(e) => 
                //   dispatch(
                //     setFilter({ id: e.target.value, filter: 'property_type' })
                //   )
                // }
                defaultValue={
                  property_type ? property_type : property_types[0].label
                }
              >
                {optionList({ items: property_types, dispatch })}
              </select>
            </div>
            <div className='form-group'>
              <label>Tipo de operación</label>
              <select
                className='form-control'
                disabled={!!property_code}
                style={{ backgroundImage: `url(../images/arrow-select.svg)` }}
                onChange={(e) => filter(e, 'operation_type')}
                // onChange={(e) =>
                //   dispatch(
                //     setFilter({ id: e.target.value, filter: 'operation_type' })
                //   )
                // }
                defaultValue={
                  operation_type ? operation_type : operation_types[0].label
                }
                key={operation_type ? operation_type : operation_types[0].label}
              >
                {optionList({ items: operation_types, dispatch })}
              </select>
            </div>
            <div className='form-group'>
              <label>Precio Mínimo</label>
              <input
                type='text'
                disabled={!!property_code}
                pattern='[0-9.]*'
                inputMode='numeric'
                className='form-control'
                name='min_price'
                onChange={(e) =>
                  dispatch(setFilterMinPrice({ value: e.target.value }))
                }
                placeholder='0'
              />
            </div>
            <div className='form-group'>
              <label>Precio Máximo</label>
              <input
                type='text'
                disabled={!!property_code}
                pattern='[0-9.]*'
                inputMode='numeric'
                className='form-control'
                name='max_price'
                onChange={(e) =>
                  dispatch(setFilterMaxPrice({ value: e.target.value }))
                }
                placeholder='99.999.999'
              />
            </div>

            <button type='submit' className='btn mt-3' ref={buttonRef}>
              <img
                className='img-fluid'
                src='../images/check.svg'
                alt='icon check'
              />
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Seacher
