export const setProperties = (properties) => ({
  type: 'SET_PROPERTIES',
  data: properties,
})

export const addMoreProperties = (properties) => ({
  type: 'ADD_MORE_PROPERTIES',
  data: properties,
})

export const setMetaInfo = (metaInfo) => ({
  type: 'SET_META_INFO',
  data: metaInfo,
})

export const enableLoading = () => ({
  type: 'TOGGLE_LOADING',
  data: true,
})

export const disableLoading = () => ({
  type: 'TOGGLE_LOADING',
  data: false,
})

export const setFilter = ({ filter, id }) => ({
  type: 'SET_FILTER',
  data: { filter, id },
})

export const setFilterMinPrice = ({ value }) => ({
  type: 'SET_FILTER_MIN_PRICE',
  data: value,
})

export const setFilterMaxPrice = ({ value }) => ({
  type: 'SET_FILTER_MAX_PRICE',
  data: value,
})

export const setPropertyCode = ({ value }) => ({
  type: 'SET_PROPERTY_CODE',
  data: value,
})