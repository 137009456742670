import React from 'react';

class ToPrint extends React.Component {
  render() {
    // console.log(typeof(this.props.photos));
    const images = this.props.photos ? this.props.photos : null;
    return (
      <table style={{ width: '100%', margin: '50px 0' }}>
        <tbody>
          <tr>
            <td style={{ width: '49%', float: 'left' }} >
              {/* título */}
              <div style={{ fontSize: '28px', color: '#3F6C6C', fontWeight: 700}}>
                {this.props.title}. {this.props.address}. Estado {this.props.condition}.
              </div>
              <div style={{ height: '30px', display: 'block', width: '100%' }}></div>
              <div style={{ marginBottom: '30px' }}>
                <p style={{ fontSize: '24px', color: '#020202', fontWeight: 700, marginTop: '30px', marginBottom: '5px', borderBottom: '1px solid #CCCCCC'}}>INFORMACIÓN BÁSICA</p>
                <ul style={{ fontSize: '18px', color: '#000000', marginTop: '5px', width: '100%'}}>
                  <li style={{ width: '33%', float: 'left' }}>Dormitorios: <strong> {this.props.room_amount} </strong></li>
                  <li style={{ width: '33%', float: 'left' }}>Baños: <strong> {this.props.bathroom_amount} </strong></li>
                  <li style={{ width: '33%', float: 'left' }}>Cocheras: <strong> {this.props.parking_lot_amount} </strong></li>
                </ul>
              </div>
              <div style={{ height: '20px', display: 'block', width: '100%' }}></div>
              <div style={{ marginTop: '30px', minHeight: '100px' }}>
                <p style={{ fontSize: '24px', color: '#020202', fontWeight: 700, marginBottom: '5px', borderBottom: '1px solid #CCCCCC'}}>SUFERFICIES</p>
                <ul style={{ fontSize: '18px', color: '#000000', marginTop: '5px', width: '100%' }}>
                  <li style={{ width: '50%', float: 'left' }}>Cubierta: <strong> {this.props.roofed_surface}m2 </strong></li>
                  <li style={{ width: '50%', float: 'left' }}>Semi-cubierta: <strong> {this.props.semiroofed_surface}m2 </strong></li>
                  <li style={{ width: '50%', float: 'left' }}>Superficie: <strong> {this.props.surface}m2 </strong></li>
                </ul>
              </div>
              <div style={{ height: '20px', display: 'block', width: '100%' }}></div>
              <div style={{ marginTop: '30px' }}>
                <p style={{ fontSize: '24px', color: '#020202', fontWeight: 700, marginBottom: '5px', borderBottom: '1px solid #CCCCCC'}}>DESCRIPCIÓN</p>
                <p style={{ fontSize: '18px', color: '#020202' }}>
                  {this.props.description}
                </p>
              </div>
              <div style={{ height: '20px', display: 'block', width: '100%' }}></div>
              <div style={{ marginTop: '30px' }}>
                <p style={{ fontSize: '24px', color: '#020202', fontWeight: 700, marginBottom: '5px', borderBottom: '1px solid #CCCCCC'}}>CONTACTO</p>
                <ul style={{ fontSize: '18px', color: '#000000', marginTop: '5px', width: '100%' }}>
                  <li>Teléfono: <strong> +362 4 698 256 </strong></li>
                  <li>Whatsapp: <strong> +362 469-8256 </strong></li>
                </ul>
              </div>
            </td>
            <td style={{width: '49%',marginLeft: '2%',float: 'left' }}>
              {/* Imagen */}
              <p style={{ fontSize: '20px', color: '#020202', fontWeight: 700, marginBottom: '30px' }}> <strong>{this.props.operation}</strong> - <strong>{this.props.formattedPrice}</strong> </p>

              {images
                ?
                (images.map((img) => (
                  <img key={img.original} src={img.image} style={{ width: '100%', marginBottom: '30px' }} alt={this.props.title} />
                )))
                : <img src={this.props.cover} style={{ width: '100%', marginBottom: '30px' }} alt={this.props.title} />
              }

            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
export default ToPrint;