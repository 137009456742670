import React from 'react';
import Hero from '../shared/Hero';
import Contact from '../shared/Contact';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share'

function Blog () {
  const activeShare = () => {
    document.querySelector('.popover').classList.toggle('show')
  }
  return (
    <div className='main-section blog' >
      <Hero
        image={'../images/hero-blog-agente-inmobiliario.jpg'}
        title={'¿Por qué contratar un agente inmobiliario?'}
        subtitle={'10/06/2020'}
      />
      <section className='bg-white pt-5 pb-5 blog__content'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-5'>
              <p className='text text-segoe'>
                Pese a no estar tan valorado como en otras culturas como la estadounidense, el empleo de agente inmobiliario puede jugar un papel fundamental en el proceso de venta de tu vivienda. Por tanto, el apostar por sus servicios o no dependerá, en muchas ocasiones, en la experiencia previa o que alcances el éxito en un proceso tan complejo como este.
              </p>
              <p className='text text-segoe'>
                Dicho esto, vamos a detallarte a continuación algunas de las razones por las que se nos antoja fundamental que apuestes por sus servicios.
              </p>
              <h3 className='text text-segoe mt-5'>
                Profesionales del sector Inmobiliario
              </h3>
              <p className='text text-segoe'>
                Un agente inmobiliario es una persona proactiva que se adapta a tu perfil, dependiendo de lo que necesites en un tema que está en permanente contacto con un sector tan complejo y volátil como el sector inmobiliario. Esto aporta al agente un conocimiento y una experiencia que, sin duda, resultarán decisivas para aumentar las posibilidades de vender tu vivienda.
              </p>
              <p className='text text-segoe'>
                Los agentes inmobiliarios, son perfectos conocedores de la situación real del mercado de bienes inmuebles y de la legalidad que se encuentra vigente en ese momento en dicho mercado. Todo ello tiene como resultado que toda actividad que se realice con el objetivo de conseguir la venta de su bien inmueble se realizará de una forma mucho más coherente y dentro de cualquier marco legal, por lo que la seguridad de todas estas acciones está garantizada.
              </p>
              <h3 className='text text-segoe mt-5'>
                Especializados en zonas concretas
              </h3>
              <p className='text text-segoe'>
                Un agente inmobiliario, al contrario de lo que pueda pensarse en un principio, suele estar especializado en los bienes inmuebles de una zona concreta con una agencia inmobiliaria de la zona. ¿Qué significa esto? En primer lugar, tendrá un conocimiento aún más específico del terreno en el que se mueve, ya que, como podrás imaginar, no es lo mismo realizar acciones comerciales para vender una vivienda en la periferia que en el centro de la ciudad. Por otro lado, y aún más importante, esto significa que un agente inmobiliario tendrá un número reducido de viviendas en cartera. Ello dará lugar a un servicio de una mayor calidad y mucho más especializado para todos y cada uno de estos bienes inmuebles, lo que se traducirá en unos mejores resultados tanto para el dueño de los mismos como para el agente a nivel individual.
              </p>
              <h3 className='text text-segoe mt-5'>
                Son negociadores natos
              </h3>
              <p className='text text-segoe'>
                Los agentes inmobiliarios son personas familiarizadas con el arte de la negociación. Como ya sabes, negociar es un arte que no todos manejamos, por lo que disponer de los servicios de un experto negociador asegurará que saques el mayor rendimiento a tu vivienda.
              </p>
              <h3 className='text text-segoe mt-5'>
                Conocen los canales de comercialización adecuados
              </h3>
              <p className='text text-segoe'>
                Como comentábamos anteriormente, resulta evidente pensar que no es lo mismo vender un bien inmueble en la periferia que en el centro de una ciudad. Los bienes inmuebles en general son activos diferentes a cualquier otro. En la mayor parte de los casos la compra de una casa es algo único en la vida, ya que supone una inversión de unas dimensiones bastante grandes. Por tanto, parece evidente que el proceso de comercialización de este tipo de bienes es distinto a cualquier otro.
              </p>
              <h3 className='text text-segoe mt-5'>
                Se encargan de todas las gestiones y trámites pertinentes
              </h3>
              <p className='text text-segoe'>
                Un agente inmobiliario controla todo el proceso y toda la burocracia que conlleva la compra-venta de una vivienda. Apostar por disfrutar de los servicios de un agente inmobiliario, por tanto, te ofrecerá la posibilidad de ahorrarte todo este tedioso y, en ocasiones, complejo proceso de redacción de contratos, negociación de hipotecas y precios…
              </p>
              <p className='text text-segoe'>
                Si las razones que te hemos dado te han convencido, no dudes en visitar una de nuestra oficinas asociadas y disfrutar del servicio de los agentes inmobiliarios más preparados del mercado inmobiliario.
              </p>
              <br />
              <p className='text text-segoe'>
                <strong> Fuente: https://reemprende.es/ </strong>
              </p>
            </div>
            <div className='col-12 col-md-1'></div>
            <div className='col-12 col-md-6'>
              <figure>
                <img
                  className='img-fluid'
                  src='../images/blog-imagen-agente-inmobiliario.jpg'
                  alt='¿Por qué contratar un agente inmobiliario?'
                />
              </figure>
              {/* <figure>
                <img
                  className='img-fluid'
                  src='../images/blog-2.png'
                  alt='Blog 1'
                />
              </figure> */}
            </div>
          </div>
        </div>
      </section>
      <section className='share'>
        <div className='container'>
          <div className='row'>
            <div className="col-12 col-md-4">
              <ul className='d-flex justify-content-start'>
                {/* <li className='d-flex align-items-center mr-4'>
                  <img className='' src='../images/print.svg' alt='IMPRIMIR' /> 
                  <p>IMPRIMIR </p>
                </li> */}
                {/* COMPARTIR */}
                <div className='open-popover' onClick={activeShare}>
                  <li className='d-flex align-items-center mr-4'>
                    <img
                      className=''
                      src='../images/share-alt.svg'
                      alt='COMPARTIR'
                    />
                    <p>COMPARTIR </p>
                  </li>
                  <div
                    className='popover fade bs-popover-bottom'
                    role='tooltip'
                    x-placement='bottom'
                  >
                    <div
                      className='arrow'
                      style={{ left: 'calc(50% - 8px)' }}
                    ></div>
                    <div className='popover-body'>
                      <FacebookShareButton
                        url={`https://peraltajuarez.com.ar/por-que-contratar-un-agente-inmobiliario`}
                        windowWidth={768}
                        windowHeight={768}
                        onShareWindowClose={() => activeShare}
                      >
                        <FacebookIcon size={32} round={true} />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={`https://peraltajuarez.com.ar/por-que-contratar-un-agente-inmobiliario`}
                        windowWidth={768}
                        windowHeight={768}
                        onShareWindowClose={() => activeShare}
                      >
                        <TwitterIcon size={32} round={true} />
                      </TwitterShareButton>
                      <WhatsappShareButton
                        url={`https://peraltajuarez.com.ar/por-que-contratar-un-agente-inmobiliario`}
                        windowWidth={768}
                        windowHeight={768}
                        onShareWindowClose={() => activeShare}
                      >
                        <WhatsappIcon size={32} round={true} />
                      </WhatsappShareButton>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Contact />
    </div>
  )
}

export default Blog;