import React from 'react'

const SectionEmprendimientos = () => {
  return (
    <>
      <div className='test'>
        <div className='row'>
          <div className='col-12 col-xs-12 col-sm-3 item'>
            <div
              className='welcome__card'
              style={{ backgroundImage: `url(../images/img-terraza-cortes.jpeg)` }}
              data-aos='zoom-in'
              data-aos-delay='0'
            >
              <a
                href={`https://peraltajuarez.com.ar/qr/terraza-cortes.pdf`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='welcome__card-button'>
                  {/* <button className='btn text-uppercase'> </button> */}
                </div>
              </a>
            </div>
          </div>
          <div className='col-12 col-xs-12 col-sm-3 item'>
            <div
              className='welcome__card'
              style={{ backgroundImage: `url(../images/duplex-francia.jpeg)` }}
              data-aos='zoom-in'
              data-aos-delay='0'
            >
              <a
                href={`https://peraltajuarez.com.ar/qr/duplex-francia.pdf`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='welcome__card-button'>
                  {/* <button className='btn text-uppercase'>  </button> */}
                </div>
              </a>
            </div>
          </div>
          <div className='col-12 col-xs-12 col-sm-3 item'>
            <div
              className='welcome__card'
              style={{ backgroundImage: `url(../images/la-brava.jpeg)` }}
              data-aos='zoom-in'
              data-aos-delay='0'
            >
              <a
                href={`https://labravabarrio.com.ar/`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='welcome__card-button'>
                  {/* <button className='btn text-uppercase'> Duplex Francia </button> */}
                </div>
              </a>
            </div>
          </div>
          <div className='col-12 col-xs-12 col-sm-3 item'>
            <div
              className='welcome__card'
              style={{ backgroundImage: `url(../images/edificio-troche.jpeg)` }}
              data-aos='zoom-in'
              data-aos-delay='0'
            >
              <a
                href={`https://peraltajuarez.com.ar/qr/troche.pdf`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='welcome__card-button'>
                  {/* <button className='btn text-uppercase'> Duplex Francia </button> */}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionEmprendimientos