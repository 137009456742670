import React, { useRef, useState } from 'react'
import Slider from '../shared/Carousel'
import Form from '../shared/Form'
import ButtonWp from '../shared/Buttonwp-property'
import MarkerMap from '../shared/MarkerMap'
import useFetchSingleProperty from '../hooks/useFetchSingleProperty'
// import ReactStreetview from 'react-streetview'
import GoogleMapReact from 'google-map-react'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share'
import ToPrint from '../shared/ToPrint'
import ReactToPrint from 'react-to-print'
import Carousel, { Modal, ModalGateway } from 'react-images';

function PropertyDetails(props) {
  const { id } = props.match.params
  const { property } = useFetchSingleProperty(id)
  const {
    publication_title,
    address,
    property_condition,
    description,
    photos,
    room_amount,
    bathroom_amount,
    parking_lot_amount,
    total_surface,
    roofed_surface,
    semiroofed_surface,
    surface,
    surface_measurement,
    operations,
    geo_lat,
    geo_long,
    development,
  } = property

  const formattedSurface = `${total_surface} ${surface_measurement}`
  // const price = operations ? operations[0].prices[0] : null
  // const formattedPrice =
  //   price > 0 ? `${price.currency} ${price.price.toLocaleString()}` : '...'

  const correctDescription = development ? development.description : description
  const price = operations ? operations[0].prices[0] : null
  const formattedPrice = price
    ? `${price.currency} ${price.price.toLocaleString()}`
    : '...'
  const type = operations ? operations[0].operation_type : null

  let mapsCenter = [-27.4393612, -59.0176793]
  let lat = -27.4393612
  let long = -59.0176793
  const mapsZoom = 13

  const coordsExists =
    typeof geo_lat === 'string' && typeof geo_long === 'string'
  if (coordsExists) {
    lat = parseFloat(geo_lat)
    long = parseFloat(geo_long)

    mapsCenter = [lat, long]
  }

  const activePopup = () => {
    document.querySelector('.popup').classList.toggle('active')
  }
  const activeShare = () => {
    document.querySelector('.popover').classList.toggle('show')
  }

  const componentRef = useRef()
  let coverSrc = ''
  if (photos) { // tiene las imagens del carousel
    let cover = photos[0]
    coverSrc = cover ? cover.image : '/images/destacado-1.png'
  }

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
  }

  let images = []
  if (photos) {
    images = photos.map(photo => {
      return { src: photo.original }
    });
  }

  // console.log(photos)

  return (
    <div className='main-section property-details'>
      <section className='gallery-property'>
        <div className='container'>
          <div className='row'>
            {
              photos && photos.length > 1 && photos.length < 3 ? (
                <>
                  <div className='col-12 col-md-6 item-image large-image two-columns'>
                    {
                      photos && photos[0] ? (
                        <img className='' src={photos[0].original} alt='' onClick={toggleModal} />
                      ) : null
                    }
                  </div>
                  <div className='col-12 col-md-6 item-image large-image two-columns'>
                    {
                      photos && photos[1] ? (
                        <img className='' src={photos[1].original} alt='' onClick={toggleModal} />
                      ) : null
                    }
                  </div>
                </>
              ) : null
            }

            {photos && photos.length > 2 ? (
              <>
                <div className='col-12 col-md-7 item-image large-image'>
                  {
                    photos && photos[0] ? (
                      <img className='' src={photos[0].original} alt='' onClick={toggleModal} />
                    ) : null
                  }
                </div>
                <div className='col-12 col-md-5 small-image-container'>
                  <div className='col-12  item-image small-image'>
                    {
                      photos && photos[1] ? (
                        <img className='' src={photos[1].original} alt='' onClick={toggleModal} />
                      ) : null
                    }
                  </div>
                  <div className='col-12  item-image small-image'>
                    {
                      photos && photos[2] ? (
                        <img className='' src={photos[2].original} alt='' onClick={toggleModal} />
                      ) : null
                    }
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </section>
      <section className='button-section'>
        <div className='container'>

          {
            photos && photos.length > 3 ? (
              <div className="d-flex align-items-center mr-4 cursor-pointer" onClick={toggleModal}>
                <img className='' src="/images/gallery-icon.svg" alt="GALERIA" />
                <span className='button-ver-mas'>Ver más fotos</span>
              </div>
            ) : null
          }
        </div>
      </section>



      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={toggleModal}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
      <section className='property-details__info'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-8'>
              <div className='info--header mb-4'>
                <h4 className='h4'>
                  {publication_title}. {address}. Estado {property_condition}.
                </h4>
              </div>
              <div className='info--body mb-4'>
                {/* <p className='mb-2'>{correctDescription}</p> */}
                <p className='mb-2' dangerouslySetInnerHTML={{ __html: correctDescription }}></p>
              </div>
            </div>
            <div className='col-12 col-lg-4'>
              <div className='info--fearure mb-4'>
                <div className='property-card__feature'>
                  <ul className='icon__group d-flex justify-content-between'>
                    <li className='icon'>
                      <p>
                        <img
                          className='img-fluid'
                          src='/images/floor-plan.svg'
                          alt='Icono'
                        />
                      </p>
                      <p>{room_amount}</p>
                      <div className="tooltip fade bs-tooltip-top" role="tooltip" style={{ position: 'absolute', top: '-42px', left: '-38px', width: '104px' }} x-placement='top'>
                        <div className="arrow"> </div>
                        <div className="tooltip-inner">Ambientes</div>
                      </div>
                    </li>
                    <li className='icon'>
                      <p>
                        <img
                          className='img-fluid'
                          src='/images/inodoro.svg'
                          alt='Icono'
                        />
                      </p>
                      <p>{bathroom_amount}</p>
                      <div className="tooltip fade bs-tooltip-top" role="tooltip" x-placement='top'>
                        <div className="arrow"> </div>
                        <div className="tooltip-inner">Baños</div>
                      </div>
                    </li>
                    <li className='icon'>
                      <p>
                        <img
                          className='img-fluid'
                          src='/images/auto.svg'
                          alt='Icono'
                        />
                      </p>
                      <p>{parking_lot_amount}</p>
                      <div className="tooltip fade bs-tooltip-top" role="tooltip" x-placement='top'>
                        <div className="arrow"> </div>
                        <div className="tooltip-inner">Cocheras</div>
                      </div>
                    </li>
                    <li className='icon'>
                      <p>
                        <img
                          className='img-fluid'
                          src='/images/regla.svg'
                          alt='Icono'
                        />
                      </p>
                      <p>{formattedSurface}</p>
                      <div className="tooltip fade bs-tooltip-top" role="tooltip" style={{ left: '-28px' }} x-placement='top'>
                        <div className="arrow"> </div>
                        <div className="tooltip-inner">Superficie</div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className='property-card__footer'>
                  <p>{formattedPrice}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='property-details__consult text-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <a
                className='button btn--read-more'
                href='https://api.whatsapp.com/send?phone=+5493624698256&text='
              >
                <img
                  className=''
                  src='/images/whatsapp_contacto.svg'
                  alt=''
                />{' '}
                CONSULTAR
              </a>
            </div>
          </div>
        </div>
      </section> */}
      <section className='property-details__share'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <ul className='d-flex justify-content-start'>
                {/* IMPRIMIR */}
                <div className='open-contact-popup'>
                  <ReactToPrint
                    trigger={() => (
                      <li className='d-flex align-items-center mr-4 btn-print'>
                        <img
                          className=''
                          src='/images/print.svg'
                          alt='IMPRIMIR'
                        />
                        <p>IMPRIMIR </p>
                      </li>
                    )}
                    content={() => componentRef.current}
                  />
                  <div style={{ display: 'none' }}>
                    <ToPrint
                      condition={property_condition}
                      cover={coverSrc}
                      photos={photos}
                      title={publication_title}
                      address={address}
                      description={correctDescription}
                      room_amount={room_amount}
                      bathroom_amount={bathroom_amount}
                      parking_lot_amount={parking_lot_amount}
                      formattedSurface={formattedSurface}
                      formattedPrice={formattedPrice}
                      roofed_surface={roofed_surface}
                      semiroofed_surface={semiroofed_surface}
                      surface={surface}
                      operation={type}
                      ref={componentRef}
                    />
                  </div>
                </div>
                {/* COMPARTIR */}
                <div className='open-popover' onClick={activeShare}>
                  <li className='d-flex align-items-center mr-4'>
                    <img
                      className=''
                      src='/images/share-alt.svg'
                      alt='COMPARTIR'
                    />
                    <p>COMPARTIR </p>
                  </li>
                  <div
                    className='popover fade bs-popover-bottom'
                    role='tooltip'
                    x-placement='bottom'
                  >
                    <div
                      className='arrow'
                      style={{ left: 'calc(50% - 8px)' }}
                    ></div>
                    <div className='popover-body'>
                      <FacebookShareButton
                        url={`https://peraltajuarez.com.ar/propiedades/${id}`}
                        windowWidth={768}
                        windowHeight={768}
                        onShareWindowClose={() => activeShare}
                      >
                        <FacebookIcon size={32} round={true} />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={`https://peraltajuarez.com.ar/propiedades/${id}`}
                        windowWidth={768}
                        windowHeight={768}
                        onShareWindowClose={() => activeShare}
                      >
                        <TwitterIcon size={32} round={true} />
                      </TwitterShareButton>
                      <WhatsappShareButton
                        url={`https://peraltajuarez.com.ar/propiedades/${id}`}
                        windowWidth={768}
                        windowHeight={768}
                        onShareWindowClose={() => activeShare}
                      >
                        <WhatsappIcon size={32} round={true} />
                      </WhatsappShareButton>
                    </div>
                  </div>
                </div>
                {/* CONSULTAR  */}
                <div className='open-contact-popup' onClick={activePopup}>
                  <li className='d-flex align-items-center mr-4'>
                    <img
                      className=''
                      src='/images/envelope.svg'
                      alt='CONSULTAR'
                    />
                    <p> CONSULTAR </p>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className='property-details__location'>
        <div className='container'>
          <div className='row'>
            <div className='comments'>
            </div>
            {coordsExists ? (
              <div className='col-12' style={{ height: '75vh', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyDIlCJRvjY4pSMBK47IygFxy5ZOdkBmhbs',
                  }}
                  yesIWantToUseGoogleMapApiInternals
                  defaultCenter={mapsCenter}
                  defaultZoom={mapsZoom}
                >
                  <MarkerMap lat={lat} lng={long} color='#3F6C6C' />
                </GoogleMapReact>
              </div>
            ) : (
              'Maps de propiedad no disponible'
            )}
          </div>
        </div>
      </section>
      <div className='popup'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='close' onClick={activePopup}>
              x
            </div>
            <Form title={'CONSULTAR'} property={id} />
          </div>
        </div>
      </div>
      {/* TESTEANDO COSAS LUKE
       <ButtonWp /> */}
      <ButtonWp publicationTitle={publication_title} />
    </div>
  )
}

export default PropertyDetails
