import React from 'react';
import Contact from '../shared/Contact';
import Hero from '../shared/Hero';
import ButtonWp from '../shared/Buttonwp';

function Sell() {
  return (
    <div className='main-section sell' >
      <Hero image={'../images/hero-sell.jpg'} title={'Vender'} />
      <section className='sell__description bg-white'>
        <div className='container'>
          <div className='row py-md-5 justify-content-center'>
            <div className='col-12 col-md-9 col-lg-8'>
              <p className='text text-segoe text-center'>
                Si está pensando en vender una propiedad y queres contar con la ayuda de un excelente equipo de trabajo, no dudes en preguntarnos. Nos encargamos de asesorar a nuestros clientes de todas las posibilidades que tienen. Publicamos en los mejores portales inmobiliarios y le presentamos la vivienda a nuestros clientes potenciales que encajen con el perfil.
              </p>
            </div>
            <div className='col-12 col-md-9 col-lg-8'>
              <p className='text text-segoe text-center'>
                Desde Peralta Juarez ofrecemos un servicio personalizado, ya que consideramos que cada cliente es diferente y eso nos permite trabajar con la mayor seriedad y efectividad posible. Ponemos a su disposición todos los medios y profesionales posibles, para su satisfacción.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Contact />
      <ButtonWp />
    </div>
  );
}

export default Sell;
