import React from 'react';
import { NavLink } from 'react-router-dom';
import Suscribite from '../shared/Suscribirte';

function Footer (){
  return(
    <footer className='footer'>
      <div className='container'>
        <div className='row flex-column-reverse flex-md-row'>
          <div className='col-sm-6 col-lg-3'>
            <ul className='d-flex justify-content-between footer__brands mb-5 mt-3 mt-lg-0 mb-lg-0'>
              <a href='/'><li className='text-right text-lg-left'><img className='img-fluid' src='/images/logo-negativo-peralta-juarez.svg' alt='' /></li></a>
              <a href='http://www.ccich.org.ar/' target='_blank' rel='noopener noreferrer'><li className='text-center'><img className='img-fluid' src='/images/logo-negativo-ccichaco.svg' alt='' /></li></a>
            </ul>
          </div>
          <div className='col-sm-6 col-lg-3 d-flex justify-content-between'>
            <ul className='footer__menu mb-5 mb-lg-0'>
              <li><NavLink to={`/`} className='ft-text'>Inicio</NavLink></li>
              <li><NavLink to={`/propiedades`} className='ft-text'>Propiedades</NavLink></li>
              <li><NavLink to={`/servicios`} className='ft-text'>Servicios</NavLink></li>
              <li><NavLink to={`/nosotros`} className='ft-text'>Nosotros</NavLink></li>
              <li><NavLink to={`/blog`} className='ft-text'>Blog</NavLink></li>
              <li><NavLink to={`/contacto`} className='ft-text'>Contacto</NavLink></li>
            </ul>
            <ul className='footer__social mb-5 mb-lg-0'>
              <li className=''>
                <a href='https://www.facebook.com/peraltajuareznegociosinmobiliarios' className='ft-text' target='_blank' rel='noopener noreferrer'>
                  <div className='media'>
                    <img src='/images/facebook-square-brands.svg' className='align-self-center mr-3' alt='Facebook' />
                    <div className='media-body'>
                      <p className=''>
                        Facebook
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/peraltajuarez_/' className='ft-text' target='_blank' rel='noopener noreferrer'>
                  <div className='media'>
                    <img src='/images/instagram-brands.svg' className='align-self-center mr-3' alt='Instagram' />
                    <div className='media-body'>
                      <p className=''>
                        Instagram
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href='https://www.youtube.com/channel/UC4Fhoy5WhUYuhnuk8GdLFhg/' className='ft-text' target='_blank' rel='noopener noreferrer'>
                  <div className='media'>
                    <img src='/images/youtube-brands.svg' className='align-self-center mr-3' alt='Youtube' />
                    <div className='media-body'>
                      <p className=''>
                        Youtube
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href='https://www.tiktok.com/@peralta.juarez.in?_t=8eMnoywllq9&_r=1' className='ft-text' target='_blank' rel='noopener noreferrer'>
                  <div className='media'>
                    <img src='/images/tiktok-brands.svg' className='align-self-center mr-3' alt='TikTok' />
                    <div className='media-body'>
                      <p className=''>
                        TikTok
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className='col-sm-6 col-lg-3'>
            <ul className='footer__newsletter mb-4 mb-lg-0 text-center text-md-left'>
              <li>
                <p className='ft-text mb-2'>Suscribite a nuestro newsletter</p>
              </li>
              <li>
                <Suscribite />
              </li>
            </ul>
          </div>
          <div className='col-sm-6 col-lg-3 d-flex justify-content-center justify-content-lg-center flex-column'>
            <ul className='footer__contact mb-5 mb-lg-0'>
              <li className='nombre-sucursal ft-text'>Sucursal Resistencia</li>
              <li>
                <a href='https://goo.gl/maps/8wHnDJHJEjt6CgNE8' className='ft-text' target='_blank' rel='noopener noreferrer'>
                  Pueyrredón 301
                </a>
              </li>
              <li>
                <div className='media'>
                  <img src='/images/whatsapp_contacto.svg' className='align-self-center mr-2' alt='Whatsapp' />
                  <div className='media-body'>
                    <p className=''>
                      <a href='https://api.whatsapp.com/send?phone=+5493624698256' className='ft-text' target='_blank' rel='noopener noreferrer'>
                        +362 469 8256
                      </a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className='media'>
                  <img src='/images/telefono_footer.svg' className='align-self-center mr-2 tel' alt='Teléfono' />
                  <div className='media-body'>
                    <p className=''>
                      <a href='tel:3624698256' className='ft-text' target='_blank' rel='noopener noreferrer'>
                        +362 4 698 256
                      </a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a href='mailto:contacto@peraltajuarez.com.ar' className='ft-text'>contacto@peraltajuarez.com.ar</a>
              </li>
            </ul>
            <ul className='footer__contact mb-5 mb-lg-0'>
              <li className='nombre-sucursal ft-text mt-lg-3'>Sucursal Villa Ángela</li>
              <li>
                <a href='https://goo.gl/maps/4Mgry25MD7GzYWyV9' className='ft-text' target='_blank' rel='noopener noreferrer'>
                  Echeverría 131
                </a>
              </li>
              <li>
                <div className='media'>
                  <img src='/images/whatsapp_contacto.svg' className='align-self-center mr-2' alt='Whatsapp' />
                  <div className='media-body'>
                    <p className=''>
                      <a href='https://api.whatsapp.com/send?phone=+5493735607095' className='ft-text' target='_blank' rel='noopener noreferrer'>
                        +373 560 7095
                      </a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className='media'>
                  <img src='/images/telefono_footer.svg' className='align-self-center mr-2 tel' alt='Teléfono' />
                  <div className='media-body'>
                    <p className=''>
                      <a href='tel:3735607095' className='ft-text' target='_blank' rel='noopener noreferrer'>
                        +373 5 60 7095
                      </a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a href='mailto:contacto@peraltajuarez.com.ar' className='ft-text'>contacto@peraltajuarez.com.ar</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;