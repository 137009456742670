import { useEffect, useState } from 'react'

import axios from 'axios'
import { API_KEY } from '../../env'

const useFetchSingleProperty = (id) => {
  const [property, setProperty] = useState({})
  const [error, setError] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `https://tokkobroker.com/api/v1/property/${id}/?key=${API_KEY}&format=json&lang=es-AR`
        )

        // console.log('use fetch single property ', data)
        setProperty(data)
      } catch (error) {
        setError(error)
        // console.error('ERROR WHILE FETCHING SINGLE PROPERTY', error)
      }
    }

    fetchData()
  }, [])

  return {
    property,
    error,
  }
}

export default useFetchSingleProperty
