import React, { } from 'react'
import { Link } from 'react-router-dom'
// import Modal from 'react-modal'
// import { Carousel } from 'react-responsive-carousel'

function PropertyCard({
	photos,
	publication_title,
	address,
	description,
	room_amount,
	bathroom_amount,
	parking_lot_amount,
	total_surface,
	surface_measurement,
	operations,
	id,
	development
}) {
	// const cover = photos.find((photo) => photo.is_front_cover)
	const cover = photos[0]
	const coverSrc = cover ? cover.image : '../images/destacado-1.png'
	// ! definir que variable se va a usar total_surface, surface
	const formattedSurface = `${total_surface} ${surface_measurement}`
	// ! pendiente definir si es la variable correcta
	const price = operations ? operations[0].prices[0] : null
	const operation_type = operations ? operations[0].operation_type.toLowerCase() : null 
	const formattedPrice = price
		? `${price.currency} ${price.price.toLocaleString()}`
		: '...'
	const correctDescription = development
		? development.description
		: description

	// ? popup de galería
	// const [popup, setPopup] = useState(false)

	return (
		<div className='property-card mb-3 mb-md-4'>
			{/* MODAL */}
			{/* <Modal
				isOpen={popup}
        ariaHideApp={false}
        onRequestClose={() => setPopup(false)}
        shouldCloseOnOverlayClick={false}
				style={{
					content: {
						height: '600px',
						top: 'auto',
						bottom: '50px'
					}
				}}
			>
				<div className='property-card__gallery'>
					<button
						className='close-button'
						onClick={() => setPopup(false)}
					>
						x
					</button>

					<Carousel
						showArrows={true}
						showIndicators={false}
						showThumbs={false}
						emulateTouch={true}
            showStatus={false}
					>
						{photos.map((photo) => (
							<div key={photo.original}>
								<img
									src={photo.original}
									alt='slider 1'
									style={{
										objectFit: 'contain',
										objectPosition: 'center',
                    backgroundColor: '#fff',
                    maxHeight: '450px',
                    height: '100%'
									}}
								/>
							</div>
						))}
					</Carousel>
				</div>
			</Modal> */}
			{/* MODAL */}
			<Link to={`/propiedades/${operation_type}/${publication_title.toLowerCase().replace(/\s/g, '-')}/${id}`}>
				<figure
					style={{ cursor: 'pointer' }}
				>
					<img
						className='img-fluid property-card__image'
						src={coverSrc}
						alt='Portada'
					/>
				</figure>
			</Link>
			<div className='property-card__body'>
				<Link
					to={`/propiedades/${operation_type}/${publication_title.toLowerCase().replace(/\s/g, '-')}/${id}`}
					className='btn-circle hvr-rectangle-out'
				>
					+
				</Link>
				<Link to={`/propiedades/${operation_type}/${publication_title.toLowerCase().replace(/\s/g, '-')}/${id}`}>
					<h3>{publication_title}</h3>
				</Link>
				<h6>{address}</h6>
				<p dangerouslySetInnerHTML={{__html: correctDescription}}></p>
			</div>
			<div className='property-card__feature'>
				<ul className='icon__group d-flex justify-content-between'>
					<li className='icon'>
						<p>
							<img
								className='img-fluid'
								src='../images/floor-plan.svg'
								alt='Icono'
							/>
						</p>
						<p>{room_amount}</p>
					</li>
					<li className='icon'>
						<p>
							<img
								className='img-fluid'
								src='../images/inodoro.svg'
								alt='Icono'
							/>
						</p>
						<p>{bathroom_amount}</p>
					</li>
					<li className='icon'>
						<p>
							<img
								className='img-fluid'
								src='../images/auto.svg'
								alt='Icono'
							/>
						</p>
						<p>{parking_lot_amount}</p>
					</li>
					<li className='icon'>
						<p>
							<img
								className='img-fluid'
								src='../images/regla.svg'
								alt='Icono'
							/>
						</p>
						<p>{formattedSurface}</p>
					</li>
				</ul>
			</div>
			<div className='property-card__footer'>
				<p>{formattedPrice}</p>
			</div>
		</div>
	)
}

export default PropertyCard
