import React from 'react'
import Seacher from '../shared/searcher'
import PropertyList from '../shared/property-list'
import Hero from '../shared/Hero'
import ButtonWp from '../shared/Buttonwp'

import useFetchProperties from '../../hooks/useFetchProperties'

import { useSelector } from 'react-redux'

const Property = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const operationId = params.get('operacion')
  const { loading } = useFetchProperties(operationId)

  const { properties } = useSelector((state) => ({
    properties: state.properties.properties,
    loading: state.properties.loading,
  }))

  if (loading) {
    return (
      <div className='main-section property'>
        <Hero
          image={'../images/hero-property.jpg'}
          title={''}
          subtitle={''}
        />
        <section className='bg-white pt-5 pb-5'>
          <div className='container'>
            <div className='row'>
              <Seacher list={false} showDesktop={true} />
              <div className='col-12 col-md-9 px-md-0 pl-xl-3'>
                <h3 className='h3 mb-md-4 desktop'>Todas las propiedades</h3>

                <div className='d-flex h-100 justify-content-center align-items-center'>
                  <div className='spinner-border text-normal' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ButtonWp />
      </div>
    )
  } else if (properties.length >= 1) {
    return (
      <div className='main-section property'>
        <Hero
          image={'../images/hero-property.jpg'}
          title={''}
          subtitle={''}
        />
        <section className='bg-white pt-5 pb-5'>
          <div className='container'>
            <div className='row'>
              <Seacher list={false} showDesktop={true} />
              <div className='col-12 col-md-9 px-md-0 pl-xl-3'>
                <h3 className='h3 mb-md-4 desktop'>Todas las propiedades</h3>

                <PropertyList
                  mdColumn={4}
                  lgColumn={4}
                  properties={properties}
                />
              </div>
            </div>
          </div>
        </section>
        <ButtonWp />
      </div>
    )
  } else {
    return (
      <div className='main-section property'>
        <Hero
          image={'../images/hero-property.jpg'}
          title={'Propiedades'}
          subtitle={'¿Qué buscas hoy?'}
        />
        <section className='bg-white pt-5 pb-5'>
          <div className='container'>
            <div className='row'>
              <Seacher list={false} showDesktop={true} />
              <div className='col-12 col-md-9 px-md-0 pl-xl-3'>
                <h3 className='h3 mb-md-4 desktop text-muted'>
                  No se encontraron propiedades
                </h3>
              </div>
            </div>
          </div>
        </section>
        <ButtonWp />
      </div>
    )
  }
}

export default Property
